import { keyframes, styled } from '@/stitches.config';

export const animateProgressBar = keyframes({
  '0%': { transform: 'scaleX(0)' },
  '100%': { transform: 'scaleX(1)' },
});

const StyledPaginationBar = styled('div', {
  position: 'relative',
  width: '100%',
  pe: 'none',
  zIndex: 1,
  '& .swiper-pagination': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 6,
    fontSize: 0,
    '@sm': {
      columnGap: 8,
    },
  },
  '& .swiper-pagination-bullet': {
    position: 'relative',
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: 28,
    height: 3,
    cursor: 'pointer',
    pe: 'all',
    '@sm': {
      maxWidth: 32,
    },
    '@lg': {
      maxWidth: 40,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 'calc(50% - 24px)',
      left: 0,
      height: 48,
      width: '100%',
      bc: 'rgba(0, 0, 0, 0)',
    },
    '& span': {
      position: 'relative',
      display: 'block',
      height: '100%',
      width: '100%',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
      },
      '&::before': {
        opacity: 0.3,
      },
      '&::after': {
        opacity: 0,
        transition: 'opacity 1s cubic-bezier(0.5, 0, 0, 0.75) 0s',
      },
    },
    '&.swiper-pagination-bullet-active': {
      '& span': {
        '&::after': {
          opacity: 1,
        },
      },
    },
  },
  variants: {
    justify: {
      start: {
        '& .swiper-pagination': {
          justifyContent: 'flex-start',
        },
      },
      center: {
        '& .swiper-pagination': {
          justifyContent: 'center',
        },
      },
      end: {
        '& .swiper-pagination': {
          justifyContent: 'flex-end',
        },
      },
    },
    variant: {
      white: {
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::before, &::after': {
              bc: '$white',
            },
          },
        },
      },
      black: {
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::before': {
              bc: '$gray1000',
            },
            '&::after': {
              bc: '$primary',
            },
          },
        },
      },
    },
    animate: {
      true: {
        '--duration': '8s',
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::after': {
              opacity: 1,
              transform: 'scaleX(0)',
              transformOrigin: 'left',
            },
          },
          '&.swiper-pagination-bullet-active': {
            '& span': {
              '&::after': {
                animationName: `${animateProgressBar}`,
                animationDuration: 'var(--duration)',
                animationTimingFunction: 'linear',
                animationIterationCount: 1,
                animationDirection: 'alternate',
                animationFillMode: 'none',
              },
            },
          },
        },
      },
    },
    stopAnimate: {
      true: {
        '& .swiper-pagination-bullet': {
          '&.swiper-pagination-bullet-active': {
            '& span': {
              '&::after': {
                animationPlayState: 'paused',
              },
            },
          },
        },
      },
    },
    animateBanner: {
      true: {
        '--duration': '8s',
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::after': {
              opacity: 1,
              transform: 'scaleX(0)',
              transformOrigin: 'left',
            },
          },
        },
      },
    },
    startAnimateBanner: {
      true: {
        '& .swiper-pagination-bullet': {
          '&.swiper-pagination-bullet-active': {
            '& span': {
              '&::after': {
                animationName: `${animateProgressBar}`,
                animationDuration: 'var(--duration)',
                animationTimingFunction: 'linear',
                animationFillMode: 'forwards',
                animationPlayState: 'running',
              },
            },
          },
        },
      },
    },
    pauseActiveBar: {
      true: {
        '& .swiper-pagination-bullet': {
          '&.swiper-pagination-bullet-active': {
            '& span': {
              '&::after': {
                animationPlayState: 'paused !important',
              },
            },
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'white',
  },
});

interface IProps extends React.ComponentProps<typeof StyledPaginationBar> {
  children?: React.ReactNode;
  css?: any;
  className?: string;
}

export const PaginationBar: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const options = {
    justify: props?.justify,
    variant: props?.variant,
    animate: props?.animate,
    stopAnimate: props?.stopAnimate,
    animateBanner: props?.animateBanner,
    startAnimateBanner: props?.startAnimateBanner,
    pauseActiveBar: props?.pauseActiveBar,
  };
  return (
    <StyledPaginationBar
      {...options}
      css={props.css}
      className={props?.className}
    >
      {props?.children}
    </StyledPaginationBar>
  );
};
PaginationBar.displayName = 'SharedMY24PaginationBar';
