import Image from 'next/image';
import { useEffect, useState } from 'react';

import { keyframes, styled } from '@/stitches.config';
import { IRatio } from '@/types/shared';

const FadeAnimation = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const CardRippleOverlay = styled('div', {
  '@lg': {
    display: 'block',
    position: 'absolute',
    left: '-1px',
    top: '-1px',
    width: 'calc(100% + 2px)',
    height: 'calc(100% + 2px)',
    bc: '$backdrop70',
    zIndex: '1',
    overflow: 'hidden',
    pe: 'none',
    clipPath: 'circle(0 at 50% 50%)',
    '--transition-duration': '0.4s',
  },
});

const NextImageWrap = styled('div', {
  overflow: 'hidden',
  fontSize: 0,
  variants: {
    hoverScaleImage: {
      true: {
        '& img': {
          '--transition-duration': '1s',
          transitionProperty: 'transform',
          transitionDuration: 'var(--transition-duration)',
          transitionTimingFunction: 'var(--transition-easing)',
        },
        '@hover': {
          '&.is-scale-image, &:hover': {
            cursor: 'pointer',
            '& img': {
              transform: 'scale(1.05)',
            },
          },
        },
      },
    },
    hoverRippleOverlay: {
      true: {
        '@lg': {
          '@hover': {
            '&.is-ripple-overlay, &:hover': {
              [`& ${CardRippleOverlay}`]: {
                '--transition-duration': '0.5s',
                clipPath: 'circle(70.71068% at 50% 50%)',
                transitionProperty: 'clip-path',
                transitionDuration: 'var(--transition-duration)',
              },
            },
          },
        },
      },
    },
    fade: {
      true: {
        opacity: 0,
        '&.is-load': {
          opacity: 1,
          animationName: FadeAnimation,
          animationDuration: '0.6s',
          animationTimingFunction: 'ease',
          animationFillMode: 'both',
        },
      },
    },
    layout: {
      fixed: {},
      fill: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      },
      intrinsic: {},
      responsive: {
        position: 'relative',
      },
    },
  },
  defaultVariants: {
    layout: 'responsive',
  },
});

interface INextImageProps {
  src: string;
  srcDesktop?: string;
  alt?: string;
  width?: number;
  height?: number;
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive';
  quality?: number;
  priority?: boolean;
  loading?: 'lazy' | 'eager';
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  objectPosition?: 'center top' | 'center center' | 'center bottom';
  sizes?: string | undefined;
  placeholder?: 'blur' | 'empty';
  // blurDataURL?: string;
  blur?: number;
  fade?: boolean;
  unoptimized?: boolean;
  className?: string | undefined;
  cropByRatio?: IRatio;
  cropByRatioDesktop?: IRatio;
  hoverScaleImage?: boolean;
  hoverChildScaleImage?: boolean;
  hoverRippleOverlay?: boolean;
  hoverChildRippleOverlay?: boolean;
  dataTest?: string | undefined;
  onClick?: () => void;
  bgColor?: string;
  onLoad?: () => void;
  lazyRoot?: React.RefObject<HTMLElement> | null;
  lazyBoundary?: string;
  css?: any;
}

export const NextImage: React.FunctionComponent<INextImageProps> = (
  props: INextImageProps
) => {
  const [load, setLoad] = useState(false);

  const contentstackIoImageBuilder = ({ src, width, quality }) => {
    let URL_WITH_OPTIONS: string;
    if (props.srcDesktop && width > 1200) {
      URL_WITH_OPTIONS = `${props.srcDesktop}?format=pjpg&auto=webp`;
    } else {
      URL_WITH_OPTIONS = `${src}?format=pjpg&auto=webp`;
    }

    const OPTIONS: string[] = [];

    if (width) {
      OPTIONS.push(`width=${width}`);
    }

    if (props.cropByRatio) {
      if (props.cropByRatioDesktop && width > 1200) {
        OPTIONS.push(`crop=${props.cropByRatioDesktop}`);
      } else {
        OPTIONS.push(`crop=${props.cropByRatio}`);
      }
    }

    if (props.blur) {
      OPTIONS.push(`blur=${props.blur}`);
    }

    if (props.bgColor) {
      OPTIONS.push(`bg-color=${props.bgColor}`);
    }

    if (quality) {
      OPTIONS.push(`quality=${quality}`);
    }

    OPTIONS.forEach((o) => {
      URL_WITH_OPTIONS += `&${o}`;
    });

    return URL_WITH_OPTIONS;
  };

  const urlImageBlur = () => {
    let URL_WITH_OPTIONS = `${props.src}?format=pjpg&auto=webp&width=150&blur=40`;

    const OPTIONS: string[] = [];

    if (props.cropByRatio) {
      OPTIONS.push(`crop=${props.cropByRatio}`);
    }

    if (props.bgColor) {
      OPTIONS.push(`bg-color=${props.bgColor}`);
    }

    if (props.quality) {
      OPTIONS.push(`quality=${props.quality || 75}`);
    }

    OPTIONS.forEach((o) => {
      URL_WITH_OPTIONS += `&${o}`;
    });

    return URL_WITH_OPTIONS;
  };

  const handleImageLoad = () => {
    setLoad(true);
    props.onLoad && props.onLoad();
  };

  const classNames = [];
  props.className && classNames.push(props.className);
  props.hoverChildScaleImage && classNames.push('is-scale-image');
  props.hoverChildRippleOverlay && classNames.push('is-ripple-overlay');
  load && classNames.push('is-load');

  useEffect(() => {
    setLoad(false);
  }, [props.src]);

  return (
    <NextImageWrap
      hoverScaleImage={props.hoverScaleImage}
      hoverRippleOverlay={props.hoverRippleOverlay}
      onClick={props.onClick}
      className={classNames.join(' ')}
      fade={props.fade}
      layout={props.layout}
      data-test={props?.dataTest ? props?.dataTest : 'image'}
      css={props?.css}
    >
      <Image
        loader={contentstackIoImageBuilder}
        src={props.src}
        alt={props.alt}
        width={props.layout !== 'fill' && props.width}
        height={props.layout !== 'fill' && props.height}
        layout={props.layout}
        objectFit={props.objectFit}
        objectPosition={props.objectPosition}
        loading={props.loading}
        priority={props.priority}
        placeholder={props.placeholder}
        blurDataURL={props.placeholder === 'blur' && urlImageBlur()}
        quality={props.quality}
        unoptimized={props.unoptimized}
        sizes={props.sizes}
        onLoadingComplete={handleImageLoad}
        lazyBoundary={props.lazyBoundary}
        lazyRoot={props.lazyRoot}
        data-test="image"
      />
      {props.hoverRippleOverlay && <CardRippleOverlay />}
    </NextImageWrap>
  );
};

NextImage.defaultProps = {
  priority: false,
  quality: 75,
  layout: 'responsive',
  objectFit: 'cover',
  objectPosition: 'center center',
  unoptimized: false,
  placeholder: 'empty',
  hoverScaleImage: false,
  hoverChildScaleImage: false,
  hoverRippleOverlay: false,
  hoverChildRippleOverlay: false,
};
NextImage.displayName = 'SharedElementNextImage';
